import { defineComponent } from 'vue';
import { EditableUser } from '@/models';

export const managementMixin = defineComponent({
  methods: {

    removeValues (user: EditableUser) {
      user.firstname = '';
      user.lastname = '';
      user.email = '';
      user.password = '';
    },
    removeItem (items: EditableUser[], item: EditableUser) {
      const index = items.findIndex(t => t.id === item.id);
      if (index > -1) {
        items.splice(index, 1);
      }
    },
    addItem (items: EditableUser[], item: EditableUser) {
      items.push(item);
    }
  },
  props: {
    search: {
      type: String, required: true
    },
    passwordLength: { type: Number, required: true },
    warnOnDelete: { type: Boolean, default: true }
  }
});
