
import { defineComponent, PropType } from 'vue';
import { CommonError, EditableUser } from '@/models';
import sortMixin from '@/mixins/sortMixin';
import { confirmDelete, showError } from '@/sweetalert2/templates.ts';
import SortCaret from '@/components/common/SortCaret.vue';
import i18n from '@/i18n';

export default defineComponent({
  name: 'UserList',
  components: { SortCaret },
  emits: ['saveUser', 'refreshUser', 'deleteUser'],
  data () {
    return {};
  },
  mixins: [sortMixin],
  props: {
    passwordLength: {
      type: Number,
      required: false,
      default: 8
    },
    users: {
      type: Object as PropType<EditableUser[]>,
      required: true
    },
    warnOnDelete: { type: Boolean, default: true }

  },
  methods: {
    generateNewPassword (userId: number): void {
      const user = this.users.find(u => u.id === userId);
      if (!user) {
        return;
      }

      user.password = this.generatePassword(this.passwordLength);
    },
    getRandomByte () {
      // http://caniuse.com/#feat=getrandomvalues
      if (window.crypto && window.crypto.getRandomValues) {
        const result = new Uint8Array(1);
        window.crypto.getRandomValues(result);
        return result[0];
      } else {
        return Math.floor(Math.random() * 256);
      }
    },

    generatePassword (length: number): string {
      const chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let password = '';
      for (let i = 0; i < length; i++) {
        const randomNumber = Math.floor(this.getRandomByte() / 256 * chars.length);
        password += chars.substring(randomNumber, randomNumber + 1);
      }
      return password;
    },
    validateEmail (email: string) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    saveUser (user: EditableUser) {
      if (!user.firstname || !user.lastname || !user.email || (user.id === -1 && !user.password)) {
        showError(this.$t("messages.fill all fields"));
        return;
      } else if (!this.validateEmail(user.email)) {
        showError(this.$t("messages.valid email"));
        return;
      }
      this.$emit('saveUser', user);
    },
    refreshUser (user: EditableUser) {
      this.$emit('refreshUser', user);
    },
    async deleteUser (user: EditableUser) {
      if (this.warnOnDelete) {
        const result = await confirmDelete(this.$t("messages.Delete?"), `${this.$t("messages.del user?")} (${user.email})`);
        if (!result.isConfirmed) {
          return;
        }
      }

      this.$emit('deleteUser', user);
    }
  },
  computed: {
    sortedUsers () {
      this.sortItems<EditableUser>(this.users);
      return this.users;
    }
  }
});
