import { defineComponent } from 'vue';

export const downloadMixin = defineComponent({
  methods: {
    downloadFile (data: string, anchor: HTMLAnchorElement) {
      const file = new Blob([data], { type: 'text/txt' });
      anchor.href = URL.createObjectURL(file);
      anchor.download = 'passwords.txt';
    }
  }
});
